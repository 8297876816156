body {
  margin: 0px;
}

/* Var names */
:root {
  --green-50: #dcecce;
  --green-100: #aacd8a;
  --green-200: #7ba951;
  --green-400: #59872e;
  --green-800: #29490c;

  --blue-50: #cff1f2;
  --blue-100: #7cd8db;
  --blue-200: #4eabad;
  --blue-400: #367072;
  --blue-800: #162b2c;

  --gray-50: #f7f8f8;
  --gray-100: #eaefef;
  --gray-200: #dadfdf;
  --gray-400: #9fa7a7;
  --gray-800: #444949;

  /* Base */
  --white: #ffffff;
  --black: #000000;

  /* Utilitary Colours */
  --error-50: #fecdca;
  --error-100: #f97066;
  --error-200: #b42318;

  --warning-50: #fedf89;
  --warning-100: #fdb022;
  --warning-200: #f9841e;

  --success-50: #32d583;
  --success-100: #0aa174;
  --success-200: #039855;

  --info-100: #4fa5f5;

  --primary-blue: #00aeae;
}

/* Loading animation */
.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loading-animation-table {
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
  height: 90%;
}

iframe {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* SnackBar override angular */
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: 0px 0px 0px 0px !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

/* Override toolTip */

.mat-mdc-tooltip .mdc-tooltip__surface {
  border: 1px solid var(--primary-blue-50, #cff1f2) !important;
  background-color: white !important;
  opacity: 0.7 !important;
  color: #162b2c !important;
  font-family: Poppins !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
}

/* OVERRIDE STEPPER */
.mat-horizontal-stepper-header-container {
  width: 30%;
  align-self: center;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #00aeae;
}

.mat-horizontal-stepper-wrapper {
  background-color: #f5f8f3 !important ;
}

.mat-step-text-label {
  text-overflow: ellipsis;
  overflow: hidden;
  display: none;
}

.mat-step-label.mat-step-label-selected {
  font-size: var(--mat-stepper-header-selected-state-label-text-size);
  font-weight: var(--mat-stepper-header-selected-state-label-text-weight);
  display: none;
}

.mat-step-label.mat-step-label-active {
  color: var(--mat-stepper-header-selected-state-label-text-color);
  display: none;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
  top: 0px !important;
  position: relative;
  /*align-self: center;*/
}

.ngx-mat-file-input {
  height: 30px;
  max-height: 30px;
}

.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00aeae;
  --mat-mdc-button-persistent-ripple-color: #00aeae;
  --mat-mdc-button-ripple-color: #00aeae;
  margin-right: -20px;
}

.mat-mdc-select-arrow svg {
  fill: #00aeae;
}

.cdk-overlay-pane {
  margin-left: 13px;
}

.mat-step-icon {
  height: 30px !important;
  width: 30px !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin: none !important;
}

.mat-step-header .mat-step-header-ripple {
  width: 0px;
  height: 0px;
}

.bg-color {
  background-color: #f5f8f3;
  margin-bottom: 32px;
}

/* Angular toogle override */
.mdc-switch:enabled .mdc-switch__track::after {
  background-color: #2e4849 !important;
  --mdc-switch-selected-handle-color: var(--primary-blue) !important;
}
.mat-mdc-slide-toggle.mat-accent:focus {
  --mdc-switch-selected-handle-color: var(--primary-blue) !important;
  --mdc-switch-selected-hover-handle-color: var(--primary-blue) !important;
  --mdc-switch-selected-handle-color: var(--primary-blue) !important;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-hover-handle-color: var(--primary-blue) !important;
  --mdc-switch-selected-handle-color: var(--primary-blue) !important;
  --mdc-switch-selected-focus-handle-color: var(--primary-blue) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primary-blue) !important;
  --mdc-switch-unselected-handle-color: #00aeae9e;
  --mdc-switch-unselected-hover-handle-color: var(--primary-blue) !important;
  --mdc-switch-unselected-focus-handle-color: #00aeae9e;

  outline: none;
}

.mat-icon-button ::ng-deep .mat-button-focus-overlay {
  display: none;
}

.mat-button-toggle:active {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mat-button-toggle:focus {
  outline: none;
}

button:focus {
  outline: none !important;
}
/* Angular Material overrides */
.mat-mdc-tab .mdc-tab__content {
  color: var(--primary-blue-800, #162b2c);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mat-mdc-tab-header {
  --mdc-tab-indicator-active-indicator-height: 0px !important;
}

.mat-mdc-form-field-flex {
  height: 40px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

.mdc-line-ripple {
  display: none;
}

.mdc-text-field {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background: var(--white);
}

.custom-select:focus {
  border-color: var(--primary-blue);
  outline: none;
  box-shadow: none;
}

.app-dialog-encomendas {
  border-radius: 8px;
  border: 1px solid #7cd8db;
  background: #fff;
  box-shadow: -4px 4px 17px 0px #ececec;
}

div.mat-mdc-select-panel {
  margin-left: -13px;
}

html {
  --mat-option-label-text-font: Poppins;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
  --mdc-icon-button-icon-color: #00aeae;
  --mat-select-focused-arrow-color: var(--blue-50) !important;
  --mat-select-invalid-arrow-color: #00aeae !important;
  --mdc-theme-error: #f97066 !important;
  --mat-stepper-line-color: #cff1f2 !important;
  --mat-stepper-header-icon-background-color: #71d2d6;
  --mat-stepper-header-selected-state-icon-background-color: #00aeae;
  --mat-stepper-header-done-state-icon-background-color: var(
    --white
  ) !important;
  --mdc-text-button-label-text-color: #fff !important;
  --mat-stepper-header-edit-state-icon-background-color: #00aeae !important;
  --mdc-theme-primary: var(--primary-blue) !important;
  --mat-option-selected-state-label-text-color: var(--primary-blue) !important;

  --mat-datepicker-calendar-date-selected-state-text-color: var(--white);
  --mat-datepicker-calendar-date-selected-state-background-color: var(
    --primary-blue
  );
  --mat-datepicker-calendar-date-hover-state-background-color: var(--blue-50);
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-blue);
  --mat-datepicker-calendar-body-label-text-color: var(--primary-blue);
  --mat-datepicker-calendar-period-button-icon-color: var(--primary-blue);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--primary-blue);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--blue-50);
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primary-blue) !important;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  color: var(--blue-800);
  font-family: Poppins !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--white) !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
}

.mat-mdc-option:hover {
  background: var(--blue-50) !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: var(--white) !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error) !important;
}

.mdc-line-ripple::before {
  border-bottom-width: 0px;
}

.mat-mdc-paginator-range-label {
  inline-size: max-content !important;
}

.mdc-text-field--filled:not(
    .mdc-text-field--disabled
  ):hover.mdc-line-ripple::before {
  border-bottom-color: var(--success-100) !important;
}

.mdc-line-ripple {
  border-bottom-width: 0px !important;
}

.mdc-line-ripple::before {
  border-bottom-width: 0px !important;
}

.label {
  display: flex !important;
}

.mdc-tab__text-label {
  color: var(--blue-800) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--primary-blue) !important;
}

.mat-mdc-card-subtitle {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--blue-800) !important;
}

.mdc-button {
  font-family: "Poppins";
}

.mat-mdc-button > .mat-icon {
  height: auto !important;
  width: 24px !important;
}

.mat-mdc-outlined-button > .mat-icon {
  height: auto !important;
  width: 24px !important;
}

.mat-mdc-tab {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  text-align: center;
}

.mat-mdc-tab.mdc-tab:hover {
  background-color: var(--blue-50);
}

.mat-mdc-button.mat-unthemed {
  color: var(--primary-blue) !important;
}

.mdc-floating-label {
  color: var(--primary-blue) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primary-blue) !important;
}

.mat-mdc-header-row {
  font-family: "Poppins" !important;
  font-size: 16px !important;
}

.mat-mdc-row {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
}

.mat-mdc-paginator {
  font-family: "Poppins" !important;
}

.mat-mdc-tab-group {
  height: 100%;
}
.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.mat-mdc-tab-body {
  display: flex !important;
  flex-direction: column;
}

.mat-mdc-tab-body-content {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.mdc-tab--active.mdc-tab-indicator--active {
  border-bottom: 2px solid var(--primary-blue, #00aeae);
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-family: Poppins !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

/* Global CSS */
figure {
  margin: 0 0 0rem;
}

.main-content {
  display: flex;
  flex-direction: row;
}

.right-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  flex-grow: 1;
  padding: 0px 45px 0px 45px;
}

.link {
  color: var(--primary-blue);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.link:hover {
  cursor: pointer !important;
  color: var(--green-100);
}

input {
  font-family: Poppins;
}

/* Fonts */
h1 {
  font-size: 34px;
  font-family: Poppins-Bold;
}

h2 {
  font-size: 22px;
  font-family: Poppins-SemiBold;
}

h3 {
  font: 18px;
  font-family: Poppins-SemiBold;
}

h4 {
  color: var(--blue-800);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.defaultFont {
  font-size: 16px;
  font-family: Poppins;
}

.secundaryFont {
  font-size: 14px;
  font-family: Poppins;
}

.secundaryButtons {
  font-size: 12px;
  font-family: Poppins;
}

.terciaryFont {
  font-size: 10px;
  font-family: Poppins;
}

.centering {
  display: flex;
  justify-content: center;
  align-content: center;
}

.mat-mdc-tab-labels {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  justify-content: center;
}

@font-face {
  font-family: Poppins-Black;
  src: url(assets/Poppins/Poppins-Black.ttf);
}

@font-face {
  font-family: Poppins-ExtraBold;
  src: url(assets/Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(assets/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(assets/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(assets/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(assets/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Light;
  src: url(assets/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins-ExtraLight;
  src: url(assets/Poppins/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: Poppins-Thin;
  src: url(assets/Poppins/Poppins-Thin.ttf);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-30 {
  opacity: 30%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dcecce99;
  display: none;
  z-index: 90;
}

.app-backdrop.open {
  display: block;
}

button.mat-mdc-menu-item:not(:last-child) div.mat-ripple.mat-mdc-menu-ripple {
  border-bottom: 1px solid #cff1f2 !important;
  width: 90%;
  margin: 0 auto;
}

.cdk-global-scrollblock {
  position: relative;
  overflow-y: hidden;
  top: 0 !important;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--primary-blue);
}

@media screen and (max-width: 500px) {
  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    max-width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .right-content {
    flex-grow: 1;
  }

  .cdk-global-overlay-wrapper {
    width: 100% !important;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 15px !important;
  }
}

/* Ultrawide Adjusts */
@media screen and (min-width: 1700px) {
  .ultraWAdjusts {
    max-width: 1700px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
